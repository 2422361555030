<template>
  <div id="common-picture" class="h-100">
    <div class="father">
      <div class="outPics" id="scroll_pics" @mouseenter="clearTime()" @mouseleave="leave()" v-viewer>
        <div v-for="(item,index) in List" :key="index" class="pic-box">
          <div class="pic-header">
            <img class="image" v-lazy="baseUrl+item.img"/>
          </div>
          <span class="describe">{{ item.title}}</span>
        </div>

      </div>
<!--      <div class="btn-page">-->
<!--        <img src="../../../assets/images/toLeft.png" @click="prePage()" class="pre_page" />-->
<!--        <img src="../../../assets/images/toRight.png" @click="nextPage()" class="next_page"/>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "pictures",
  props:['List'],
  data() {
    return {
      timer:null,
      // baseUrl: process.env.VUE_APP_BASE_API,
      baseUrl:'http://hunanqunyinghui.oss-cn-guangzhou.aliyuncs.com/',
    };
  },
  mounted() {
    this.autoSroll("scroll_pics");
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    autoSroll(Id) {
      var h = -1;
      this.timer =  setInterval(function() {
        // flag = true;
        //获取当前滚动条高度
        var current = document.getElementById(Id).scrollTop;
        if (current == h) {
          //滚动到底端,返回顶端
          h = 0;
          document.getElementById(Id).scrollTop = h + 1;
        } else {
          //以25ms/3.5px的速度滚动
          h = current;
          document.getElementById(Id).scrollTop = h + 1;
        }
      }, 50);
    },
    clearTime(){
      clearInterval(this.timer);
      this.timer = null;
    },
    leave(){
      this.autoSroll("scroll_pics");
    }
  },
};
</script>

<style lang="less">
#common-picture{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 1%;
  .father{
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .outPics{
    margin-top: 3%;
    box-sizing: border-box;
    border: 0;
    display: flex;
    flex-direction: column;
    //justify-content: space-around;
    width: 100%;
    height: 90%;
    overflow-y: scroll;
  }
  .pic-box {
    box-sizing: border-box;
    border: 0;
    width: 92%;
    height: auto;
    margin: 0 auto;
    outline: none !important;
    margin-top:3%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;

   .pic-header{
     width: 100%;
     height: auto;
     border-radius: 5px;
     background-color: #acc8e8;
     box-sizing: border-box;
     padding: 2px 2px 0 2px;
     .image{
       width: 100%;
       height: auto;
       object-fit: cover;
     }
   }
    .describe{
      display: inline-block;
      width: 100%;
      overflow: hidden;        /*内容会被修剪，并且其余内容是不可见的*/
      text-overflow:ellipsis;  /*显示省略符号来代表被修剪的文本。*/
      white-space: nowrap;     /*文本不换行*/
      //height: 10%;
      font-size: 15px;
      text-align: center;
      color: #b3ebf3;
      font-family:"华文仿宋",Georgia,"Times New Roman",Times,serif;
    }
  }
  .on-pic-box{
    width: 25%!important;
    height: 90%!important;
  }
  .btn-page{
    display: flex;
    width: 100%;
    margin: 0 auto;
    margin-top: 4.5%;
    justify-content: flex-end;
    .pre_page,.next_page{
      width: 35px;
      height: 35px;
      //line-height: 30px;
      //background-color: #83b1e7;
      //color: #fff7e6;
      //font-size: 12px;
      //text-align: center;
    }
    .next_page{
      margin-left:2%;
    }
  }
  .active{
    background-color: #56b9e8 !important;
  }
  .operate-btn {
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
  .operate-btn:hover {
    opacity: 0.8;
  }
  .fade-out {
    opacity: 0;
  }
}

.outPics::-webkit-scrollbar {/*滚动条整体样式*/
  width: 5px;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.outPics::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: #3a73e8;
}
.outPics::-webkit-scrollbar-track {/*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 10px;
  background: rgb(8,25,79);
}
</style>
